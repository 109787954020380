import React, { useState,useEffect,useRef} from 'react';
import QRScanner from './QRScanner'; // Assicurati che il percorso sia corretto
import { Line, Doughnut, Bar } from 'react-chartjs-2'; // Aggiungi "Bar"
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, LineController, Title, Tooltip, Legend, ArcElement, BarElement } from 'chart.js';
import { MapContainer, TileLayer, Marker, Popup , useMap } from 'react-leaflet';
import L from 'leaflet'; // Importazione di Leaflet
import 'leaflet/dist/leaflet.css'; // Importa lo stile della mappa
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Importa gli stili
import './App.css';  // Importa il file CSS nel tuo componente App.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faSearchPlus, faSearchMinus, faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import zoomPlugin from 'chartjs-plugin-zoom'; // Importa il plugin di zoom

// Registra i componenti necessari per il grafico

Chart.register(CategoryScale, LinearScale, LineElement, PointElement, LineController, Title, Tooltip, Legend, ArcElement, BarElement, zoomPlugin);

// Componente per centrare la mappa quando cambia la posizione
function MapUpdater({ position }) {
  const map = useMap();
  useEffect(() => {
    map.setView(position, 20); // Aggiorna la vista della mappa
  }, [position, map]);
  return null;
}

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [requirePasswordChange, setRequirePasswordChange] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isRecover, setIsRecover] = useState(false);
  const [loggedInUsername, setLoggedInUsername] = useState('');
  const [codiceUtente, setCodiceUtente] = useState(null);
  const [dispositivi, setDispositivi] = useState([]); // Stato per i dispositivi associati
  const [showDispositivi, setShowDispositivi] = useState(false); // Stato per mostrare i dispositivi
  const [datiLogbook, setDatiLogbook] = useState([]); // Stato per i dati del logbook
  const [showLogbook, setShowLogbook] = useState(false); // Stato per mostrare i dati del logbook
  const [selectedDispositivo, setSelectedDispositivo] = useState(null); // Stato per il dispositivo selezionato
  const [codiceIdentificaPadre, setCodiceIdentificaPadre] = useState('');
  const [codiceIdentifica, setCodiceIdentifica] = useState('');
  const [codiceInput, setCodiceInput] = useState(''); // Stato per il valore inserito nella textbox
  const [registrationSuccess, setRegistrationSuccess] = useState(null); // Stato per il successo della registrazione
  const [showQRScanner, setShowQRScanner] = useState(false); // Stato per mostrare/nascondere il lettore QR
  const [newDeviceName, setNewDeviceName] = useState(''); // Stato per il nuovo nome
  const [pesoAttuale, setPesoAttuale] = useState(0);
  const [umidita, setUmidita] = useState(0);
  const [altitudine, setAltitudine] = useState(0);
  const [rumore, setRumore] = useState(0);
  const [position, setPosition] = useState([0,0]); // Imposta la posizione iniziale della mappa
  const [thi, setThi] = useState(0);
  const mapRef = useRef(null); // Riferimento alla mappa
  const [startDate, setStartDate] = useState(new Date()); // Imposta la data iniziale
  const [endDate, setEndDate] = useState(new Date()); // Imposta la data finale
  const [require2FA, setRequire2FA] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState(''); // Stato per il codice 2FA
  const [utenteCondiviso, setUtenteCondiviso] = useState(''); // Stato per memorizzare il nome dell'utente con cui condividere
  const [sharedUsers, setSharedUsers] = useState([]);
  const [utenteSelezionato, setUtenteSelezionato] = useState('');
  const [isOwner, setIsOwner] = useState(false); // Stato per sapere se l'utente è il proprietario
  const [weightDifference, setWeightDifference] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [enableTemperatura, setEnableTemperatura] = useState(false);
  const [enableRumore, setEnableRumore] = useState(false);
  const [enableUmidita, setEnableUmidita] = useState(false);
  const [enableThi, setEnableThi] = useState(false);
  const chartRef = useRef(null);
  const temperatureChartRef = useRef(null);
  const pesoChartRef = useRef(null);
  const thiChartRef = useRef(null);
  const [lockYAxisPeso, setLockYAxisPeso] = useState(false);


  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/get-user-settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ codiceUtente })
        });
  
        if (response.ok) {
          const settings = await response.json();
          setEnableTemperatura(settings.enable_temperatura);
          setEnableRumore(settings.enable_rumore);
          setEnableUmidita(settings.enable_umidita);
          setEnableThi(settings.enable_thi);
        } else {
          console.error('Errore nel recupero delle impostazioni.');
        }
      } catch (error) {
        console.error('Errore nel recupero delle impostazioni:', error);
      }
    };
  
    fetchSettings();
  }, [codiceUtente]);

  const goToSettings = () => {
    setShowSettings(true);
  };
  useEffect(() => {
    if (datiLogbook.length > 0) {
      const difference = getWeightDifference();
      setWeightDifference(difference);
    }
  }, [datiLogbook]);

    // Effetto per pulire i dispositivi al logout
    useEffect(() => {
      if (!loggedIn) {
        setDispositivi([]); // Ripulisce i dispositivi al logout
        setShowDispositivi(false); // Nasconde la sezione dei dispositivi
      }
    }, [loggedIn]);

  useEffect(() => {
    //console.log('Utenti condivisi aggiornati:', sharedUsers); // Log per vedere se lo stato viene aggiornato
  }, [sharedUsers]);
  
//fundione di chiamata
  const handleDeviceClick = (identifica, isOwner) => {
    getLogbookData(identifica); // Funzione per ottenere i dati del logbook
    getSharedUsers(identifica); // Funzione per ottenere gli utenti condivisi
    setIsOwner(isOwner);
    //console.log(isOwner);
  };

  const apiUrl = window.location.hostname === 'localhost'
  ? 'http://192.168.1.157:3001'
  : '/api';


  useEffect(() => {
    if (datiLogbook.length > 0) {
      const ultimoDato = datiLogbook[datiLogbook.length - 1];
      setPesoAttuale(ultimoDato.peso);
      setUmidita(ultimoDato.umidita);
      setAltitudine(ultimoDato.altitudine);
      setRumore(ultimoDato.rumore);
      setPosition([ultimoDato.latitudine, ultimoDato.longitudine]); // Imposta la posizione sulla mappa
      setThi([ultimoDato.thi]);
    }
  }, [datiLogbook]);

  // Definizione di una nuova icona a goccia
const dropIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png', // Puoi sostituirla con una freccia o altra icona
  iconSize: [25, 41], // Dimensioni dell'icona
  iconAnchor: [12, 41], // Dove l'icona punta rispetto alla sua base
  popupAnchor: [1, -34], // Dove si apre il popup rispetto all'icona
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png', // Ombra dell'icona
  shadowSize: [41, 41] // Dimensioni dell'ombra
});
  

  // Dati per il grafico del peso
  const pesoData = {
    labels: datiLogbook.map(dato => new Date(dato.data).toLocaleString()),
    datasets: [
      {
        label: 'Peso (kg)',
        data: datiLogbook.map(dato => dato.peso),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        pointRadius: 0,          // Nasconde i punti sui dati
      pointHoverRadius: 5,      // Nasconde i punti quando si passa sopra
      },
    ],
  };

  // Dati per il grafico a ciambella (peso attuale)
  const pesoAttualeData = {
    labels: ['Peso Attuale', 'Resto'],
    datasets: [
      {
        data: [pesoAttuale, 200 - pesoAttuale], // pesoAttuale e differenza rispetto al massimo
        backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(192, 192, 192, 0.2)'],
        borderWidth: 1,
      },
    ],
  };

// Dati per il grafico a ciambella (umidità)
const umiditaData = {
  labels: ['Umidità Attuale', 'Resto'],
  datasets: [
    {
      data: [umidita, 100 - umidita], // Umidità attuale e differenza rispetto al massimo (100%)
      backgroundColor: ['rgba(54, 162, 235, 1)', 'rgba(192, 192, 192, 0.2)'],
      borderWidth: 1,
    },
  ],
};

// Dati per il grafico a ciambella (rumore)
const rumoreData = {
  labels: ['Rumore Attuale', 'Resto'],
  datasets: [
    {
      data: [rumore, 100 - rumore], // Rumore attuale e differenza rispetto a un massimo (ad es. 100 dB)
      backgroundColor: ['rgba(255, 206, 86, 1)', 'rgba(192, 192, 192, 0.2)'],
      borderWidth: 1,
    },
  ],
};

// Dati per il grafico a ciambella (thi attuale)
const thiData = {
  labels: ['THI Attuale', 'Resto'],
  datasets: [
    {
      data: [thi, 100 - thi], // THI attuale e differenza rispetto al massimo (ipotetico 100%)
      backgroundColor: ['rgba(153, 102, 255, 1)', 'rgba(192, 192, 192, 0.2)'],
      borderWidth: 1,
    },
  ],
};

//allineamento scritte grafico a ciambella
const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: function(chart) {
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;

    // Ottieni le opzioni di offset personalizzate dal grafico
    const offsetX = chart.config.options.textOffsetX || 0; // Offset orizzontale
    const offsetY = chart.config.options.textOffsetY || 0; // Offset verticale

    const centerX = width / 2 + offsetX;  // Regola il centraggio orizzontale
    const centerY = height / 1.5 + offsetY; // Regola il centraggio verticale
    const fontSize = (height / 114).toFixed(2);

    ctx.restore();
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';

    let text = '';
    let value = Math.round(chart.config.data.datasets[0].data[0]); // Arrotonda il valore


    if (chart.config.options.unit === 'kg') {
      text = chart.config.data.datasets[0].data[0] + ' kg';
    } else if (chart.config.options.unit === '%') {
      text = value + ' %';
    } else if (chart.config.options.unit === 'dB') {
      text = value + ' dB';
    } else if (chart.config.options.unit === 'T') {
      text = value ;
    }


    ctx.fillText(text, centerX, centerY);
    ctx.save();
  }
};
Chart.register(centerTextPlugin); // Assicurati che il plugin sia registrato

  // Opzioni per il grafico a ciambella peso
  const doughnutPesoOptions = {
    circumference: 180,
    rotation: -90,
    cutout: '70%',
    unit: 'kg',
    textOffsetX: 0,   // Regola l'offset orizzontale
    textOffsetY: 20,   // Regola l'offset verticale
    plugins: {
      centerText: centerTextPlugin,
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.label + ': ' + tooltipItem.raw + ' kg';
          }
        }
      }
    }
  };
  
// Opzioni per il grafico a ciambella umidita
const doughnutUmiditaOptions = {
  circumference: 180,
  rotation: -90,
  cutout: '70%',
  unit: '%',
  textOffsetX: 0,  // Sposta il testo di 10 pixel a destra
  textOffsetY: 20,  // Sposta il testo di 5 pixel verso l'alto
  plugins: {
    centerText: centerTextPlugin,
    tooltip: {
      callbacks: {
        label: function(tooltipItem) {
          return tooltipItem.label + ': ' + Math.round(tooltipItem.raw) + ' %';
        }
      }
    }
  }
};


// Opzioni per il grafico a ciambella rumore
  const doughnutRumoreOptions = {
    circumference: 180,
    rotation: -90,
    cutout: '70%',
    unit: 'dB', // Unità di misura per il rumore
    textOffsetX: 0,  // Sposta il testo di 10 pixel a destra
    textOffsetY: 30,  // Sposta il testo di 5 pixel verso l'alto
    plugins: {
      centerText: centerTextPlugin,
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.label + ': ' + Math.round(tooltipItem.raw) + ' dB';
          }
        }
      }
    }
  };
  
  // Opzioni per il grafico a ciambella thi
const doughnutThiOptions = {
  circumference: 180,
  rotation: -90,
  cutout: '70%',
  unit: 'T',
  textOffsetX: 0,
  textOffsetY: 20,
  plugins: {
    centerText: centerTextPlugin,
    tooltip: {
      callbacks: {
        label: function(tooltipItem) {
          return tooltipItem.label + ': ' + Math.round(tooltipItem.raw)  + 'T';
        }
      }
    }
  }
};
  
// Dati per il grafico lineare del THI
const thiLineData = {
  labels: datiLogbook.map(dato => new Date(dato.data).toLocaleString()),
  datasets: [
    {
      label: 'THI',
      data: datiLogbook.map(dato => dato.thi),
      borderColor: 'rgba(153, 102, 255, 1)',
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderWidth: 2,
      pointRadius: 0,          // Nasconde i punti sui dati
      pointHoverRadius: 5,      // Nasconde i punti quando si passa sopra
    },
  ],
};

// Opzioni per il grafico lineare del THI
const thiLineOptions = {
  maintainAspectRatio: false, // Permette al grafico di adattarsi all'altezza del contenitore
  responsive: true,
  scales: {
    x: {
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        autoSkip: true,  // Abilita l'auto-skip per ridurre la sovrapposizione delle etichette
        maxTicksLimit: 8, // Limita il numero massimo di etichette visualizzate
        callback: function(value, index, values) {
          const label = this.getLabelForValue(value);
         
          if (label.includes(',')) {
            const parts = label.split(',');
            return [parts[0], parts[1]];
          }
          return label;
        },
        padding: 10,
      }
    },
    y: {
      beginAtZero: false,
    }
  },
  plugins: {
    zoom: {
      pan: {
        enabled: !('ontouchstart' in window), // Abilita il pan solo su desktop
        mode: 'x', // Limita il pan all'asse x
      },
      zoom: {
        wheel: {
          enabled: false, // Abilita lo zoom con la rotellina del mouse su desktop
        },
        pinch: {
          enabled: false // Disabilita il pinch zoom su dispositivi touch
        },
        drag: {
          enabled: false, // Abilita lo zoom tramite drag (clic e trascina) su desktop
        },
        mode: 'x', // Limita lo zoom all'asse x
      }
    }
  }
};

  // Dati per il grafico delle temperature
  const temperatureData = {
    labels: datiLogbook.map(dato => new Date(dato.data).toLocaleString()),
    datasets: [
      {
        label: 'Temperatura 1 (°C)',
        data: datiLogbook.map(dato => dato.temp1),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderWidth: 2,
        pointRadius: 0,          // Nasconde i punti sui dati
        pointHoverRadius: 5,      // Nasconde i punti quando si passa sopra
      },
      {
        label: 'Temperatura 2 (°C)',
        data: datiLogbook.map(dato => dato.temp2),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderWidth: 2,
        pointRadius: 0,          // Nasconde i punti sui dati
        pointHoverRadius: 5,      // Nasconde i punti quando si passa sopra
      },
    ],
  };

//temperature ruota etichetta asse x
const temperatureOptions = {
  maintainAspectRatio: false, // Permette al grafico di adattarsi all'altezza del contenitore
  responsive: true,
  scales: {
    x: {
      ticks: {
        maxRotation: 45, // Ruota le etichette di 45 gradi
        minRotation: 45,
        autoSkip: true,  // Abilita l'auto-skip per ridurre la sovrapposizione delle etichette
        maxTicksLimit: 8, // Limita il numero massimo di etichette visualizzate
        callback: function(value, index, values) {
          const label = this.getLabelForValue(value); // Ottiene l'etichetta corretta

          if (label.includes(',')) {
            // Separa la parte di data e di ora
            const parts = label.split(',');
            return [parts[0], parts[1]]; // Ritorna la data e l'ora su due righe come array
          }
          return label;
        },
        padding: 10, // Aggiunge spazio tra le etichette e l'asse
      }
    },
    y: {
      beginAtZero: false, // Inizia da zero sull'asse Y
    }
  },
  plugins: {
    zoom: {
      pan: {
        enabled: !('ontouchstart' in window), // Abilita il pan solo su desktop
        mode: 'x', // Limita il pan all'asse x
      },
      zoom: {
        wheel: {
          enabled: false, // Abilita lo zoom con la rotellina del mouse su desktop
        },
        pinch: {
          enabled: false // Disabilita il pinch zoom su dispositivi touch
        },
        drag: {
          enabled: false, // Abilita lo zoom tramite drag (clic e trascina) su desktop
        },
        mode: 'x', // Limita lo zoom all'asse x
      }
    }
  }
};


  //temperature ruota etichetta asse x
  const pesoOptions = {
    maintainAspectRatio: false, // Permette al grafico di adattarsi all'altezza del contenitore
  responsive: true,
    scales: {
      x: {
        ticks: {
          maxRotation: 45, // Ruota le etichette di 45 gradi
          minRotation: 45,
          autoSkip: true,  // Abilita l'auto-skip per ridurre la sovrapposizione delle etichette
        maxTicksLimit: 8, // Limita il numero massimo di etichette visualizzate
          callback: function(value, index, values) {
            const label = this.getLabelForValue(value); // Ottiene l'etichetta corretta
           
            if (label.includes(',')) {
              // Separa la parte di data e di ora
              const parts = label.split(',');
              return [parts[0], parts[1]]; // Ritorna la data e l'ora su due righe come array
            }
            return label;
          },
          padding: 10, // Aggiunge spazio tra le etichette e l'asse
        }
      },
      y: {
        beginAtZero: false,
        max: lockYAxisPeso ? 200 : undefined, // Se `lockYAxisPeso` è true, blocca a 200 kg
      }
    },
    plugins: {
      zoom: {
        pan: {
          enabled: !('ontouchstart' in window), // Abilita il pan solo su desktop
          mode: 'x', // Limita il pan all'asse x
        },
        zoom: {
          wheel: {
            enabled: false, // Abilita lo zoom con la rotellina del mouse su desktop
          },
          pinch: {
            enabled: false // Disabilita il pinch zoom su dispositivi touch
          },
          drag: {
            enabled: false, // Abilita lo zoom tramite drag (clic e trascina) su desktop
          },
          mode: 'x', // Limita lo zoom all'asse x
        }
      }
    }
  };


// Funzione per aggiornare il nome del dispositivo
const updateDeviceName = async (identifica) => {
  // Permetti di inviare un nome vuoto o alfanumerico
  const nameRegex = /^[a-zA-Z0-9]{0,10}$/; // Accetta anche una stringa vuota

  if (!nameRegex.test(newDeviceName)) {
    alert('Il nome del dispositivo deve contenere solo caratteri alfanumerici e deve essere lungo al massimo 10 caratteri.');
    return;
  }

  const response = await fetch(`${apiUrl}/update-device-name`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      identifica, 
      nuovo_nome: newDeviceName // Può essere vuoto o alfanumerico
    }),
  });

  if (response.ok) {
    alert('Nome dispositivo aggiornato con successo!');
    setNewDeviceName(''); // Resetta il campo di input
  } else {
    alert('Errore durante l\'aggiornamento del nome del dispositivo.');
  }
};




  // Funzione per registrare il dispositivo
  const registerDevice = async () => {
    let codice_identifica_padre = '';
    let codice_identifica = '';
  
    // Verifica se il codice inserito è di 10 o 15 caratteri
    if (codiceInput.length === 10) {
      codice_identifica_padre = codiceInput;
    } else if (codiceInput.length === 15) {
      codice_identifica = codiceInput;
    } else {
      alert('Il codice inserito non è valido. Assicurati che sia di 10 o 15 caratteri.');
      return;
    }
  
    // Invia la richiesta al backend per registrare il dispositivo
    const response = await fetch(`${apiUrl}/register-device`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        codice_utente: codiceUtente, // Usa il codice utente loggato
        codice_identifica_padre,
        codice_identifica,
      }),
    });
  
    if (response.ok) {
      const data = await response.json();
      alert('Riepilogo:\n' + data.messages.join('\n')); // Mostra il riepilogo
      getDispositiviAssociati(); // Aggiorna la lista dei dispositivi
    } else {
      alert('Errore nella registrazione del dispositivo');
    }
  };
  

  // Funzione per gestire il risultato della scansione QR
  const handleQRScan = (scannedCode) => {
    setCodiceInput(scannedCode); // Imposta il codice scansionato nella textbox
    setShowQRScanner(false); // Nascondi il lettore QR una volta completata la scansione
  };


  // Funzione di registrazione
  const signUp = async () => {
    const response = await fetch(`${apiUrl}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, email })
    });
    if (response.ok) {
      alert('Registrazione riuscita! Controlla la tua email per la password temporanea.effettua questa operazione entro 5 minuti altrimenti l utente verrà eliminato');
      setIsSignUp(false);  // Torna alla schermata di login
    } else {
      const errorText = await response.text();
      alert('Errore nella registrazione: ' + errorText);
    }
  };

  // Funzione di login
/*
  const login = async () => {
    const response = await fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    });

    if (response.ok) {
      const data = await response.json();
      //console.log('Dati ricevuti dal backend:', JSON.stringify(data, null, 2));

      if (data.requirePasswordChange) {
        setRequirePasswordChange(true);
      } else if (data.success) {
        setLoggedIn(true);
        setLoggedInUsername(data.username);
        setCodiceUtente(data.codice_utente);
        //console.log(`Login eseguito, username: ${data.username}, codice utente: ${data.codice_utente}`);
      } else {
        alert('Errore durante il login');
      }
    } else {
      alert('Errore: ' + await response.text());
    }
  };
*/
const login = async () => {
  try {
    const response = await fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    });

    if (response.ok) {
      const data = await response.json();
      if (data.requirePasswordChange) {
        setRequirePasswordChange(true); // Richiedi il cambio password
      } else if (data.require2FA) {
        setRequire2FA(true); // Richiedi il codice 2FA
      } else if (data.success) {
        setLoggedIn(true);
        setLoggedInUsername(data.username);
        setCodiceUtente(data.codice_utente);
      }
    } else {
      // Aggiungi la visualizzazione dettagliata dell'errore dal backend
      const errorMessage = await response.text(); 
      alert('Errore durante il login: ' + errorMessage); // Mostra l'errore dettagliato
    }
  } catch (error) {
    console.error('Errore durante il login:', error);
    alert('Errore durante il login: ' + error.message);
  }
};

//
const verify2FA = async () => {
  try {
    const response = await fetch(`${apiUrl}/verify-2fa`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, twoFactorCode }) // Invia il codice 2FA e l'username
    });

    if (response.ok) {
      const data = await response.json();
      alert(data.message); // Messaggio di successo
      setLoggedIn(true); // Imposta lo stato come loggato
      setRequire2FA(false); // Disattiva la richiesta del 2FA
    } else {
      const errorMessage = await response.text();
      alert('Errore durante la verifica del codice: ' + errorMessage); // Mostra l'errore
    }
  } catch (error) {
    console.error('Errore durante la verifica del codice 2FA:', error);
    alert('Errore durante la verifica del codice 2FA.');
  }
};


  

  // Funzione per cambiare la password
  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Le password non corrispondono');
      return;
    }

    const response = await fetch(`${apiUrl}/change-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, newPassword, confirmPassword })
    });

    if (response.ok) {
      alert('Password cambiata con successo. Effettua nuovamente il login.');
  
      // Effettua il logout dopo il cambio password forzato
      setLoggedIn(false);
      setPassword(''); // Resetta la password
      setNewPassword(''); // Resetta il campo della nuova password
      setConfirmPassword(''); // Resetta il campo della conferma password
      setRequirePasswordChange(false); // Disabilita il cambio forzato della password
  
      // Lascia solo il campo username compilato
    } else {
      alert('Errore nel cambio della password');
    }
  };

  // Funzione per il recupero delle credenziali
  const recoverCredentials = async () => {
    const response = await fetch(`${apiUrl}/recover`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });

    if (response.ok) {
      alert('Controlla la tua email per il nome utente e la nuova password. Effettua nuovamente il login.');
  
      // Effettua il logout e resetta i campi
      setLoggedIn(false);
      setPassword(''); // Resetta la password
      setNewPassword(''); // Resetta il campo della nuova password
      setConfirmPassword(''); // Resetta il campo della conferma password
      setEmail(''); // Resetta il campo email
      setIsRecover(false); // Torna alla schermata di login
    } else {
      const errorText = await response.text();
      alert('Errore nel recupero delle credenziali: ' + errorText);
    }
  };

  

// Funzione per disassociare un dispositivo
const disassociateDevice = async (codiceInput) => {
  if (!codiceInput) {
    alert('Inserisci un codice per disassociare il dispositivo.');
    return;
  }

  try {
    const conferma = window.confirm("Sei sicuro di voler disassociare questo dispositivo?");
    if (!conferma) {
      return;
    }

    const response = await fetch(`${apiUrl}/disassociate-device`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        codice_utente: codiceUtente,
        codice_identifica: codiceInput.length === 15 ? codiceInput : null,
        codice_identifica_padre: codiceInput.length === 10 ? codiceInput : null,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      alert(data.messages.join('\n')); // Mostra tutti i messaggi in un unico alert
      getDispositiviAssociati();
    } else {
      alert(data.messages.join('\n'));
    }
  } catch (error) {
    console.error('Errore durante la disassociazione del dispositivo:', error);
    alert('Errore durante la disassociazione del dispositivo.');
  }
};






  // Funzione per ottenere i dispositivi associati all'utente
  const getDispositiviAssociati = async () => {
    const response = await fetch(`${apiUrl}/get-devices`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ codice_utente: codiceUtente })
    });
  
    if (response.ok) {
      const data = await response.json();
      //console.log('Dispositivi ricevuti:', data.dispositivi); // Verifica se contiene il campo nome
      setDispositivi(data.dispositivi); // Imposta i dispositivi nel componente
      setShowDispositivi(true); // Mostra i dispositivi
    } else {
      alert('Errore durante il recupero dei dispositivi associati.');
    }
  };
  

  // Funzione per ottenere i dati del logbook di un dispositivo specifico
  const getLogbookData = async (identifica) => {
    //console.log('Richiesta dati logbook per identificativo:', identifica); // Log nel frontend
    const response = await fetch(`${apiUrl}/get-logbook-data`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ identifica })
    });
  
    if (response.ok) {
      const data = await response.json();
      //console.log('Dati logbook ricevuti dal backend:', data); // Log dei dati ricevuti
      setDatiLogbook(data.dati); // Imposta i dati del logbook nel componente
      setSelectedDispositivo(identifica); // Imposta il dispositivo selezionato
      setShowLogbook(true); // Mostra i dati del logbook
    } else {
      alert('Errore durante il recupero dei dati del logbook.');
    }
  };
  
  // Funzione per tornare al menu precedente
  const tornaAlMenuPrecedente = () => {
    setSelectedDispositivo(null); // Resetta il dispositivo selezionato
    setShowLogbook(false); // Nasconde i dati del logbook

    // Richiama la funzione per ottenere i dispositivi associati
  getDispositiviAssociati();
  };

  // Funzione di logout
// Funzione di logout
const logout = () => {
 
  setLoggedIn(false);
  setUsername('');
  setPassword('');
  setCodiceUtente(null);
  setLoggedInUsername('');
  setRequirePasswordChange(false);
  setRequire2FA(false); // Resetta la richiesta di 2FA
  setTwoFactorCode(''); // Resetta il codice 2FA
  setDispositivi([]); // Resetta la lista dei dispositivi
  setShowDispositivi(false); // Nasconde la lista dei dispositivi
  setSelectedDispositivo(null); // Resetta il dispositivo selezionato
  setDatiLogbook([]); // Resetta i dati del logbook
  setShowLogbook(false); // Nasconde i dati del logbook
  setPosition([0, 0]); // Resetta la posizione della mappa
  setSharedUsers([]); // Resetta la lista degli utenti condivisi
  setUtenteCondiviso(''); // Resetta il campo di input dell'utente con cui condividere
  setIsOwner(false); // Resetta il flag del proprietario
  setCodiceInput(''); // Resetta il campo del codice dispositivo
  setNewDeviceName(''); // Resetta il nuovo nome del dispositivo
};

  //invio date al backend
  const filterDataByDate = async () => {
    if (!startDate || !endDate) {
      alert('Per favore seleziona sia la data di inizio che quella di fine.');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/filter-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
         // codice_utente: codiceUtente,
          codice_identifica: selectedDispositivo,  // Invia il dispositivo selezionato
          startDate: startDate.toISOString(),      // Converte la data in formato ISO
          endDate: endDate.toISOString()
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setDatiLogbook(data.dati); // Imposta i dati filtrati nella variabile datiLogbook
      } else {
        alert('Errore durante il filtraggio dei dati');
      }
    } catch (error) {
      console.error('Errore durante il filtraggio dei dati:', error);
      alert('Errore durante il filtraggio dei dati');
    }
  };
  
// Funzione per eliminare i dati del logbook
const deleteLogbookData = async () => {
  // Chiedi conferma all'utente prima di procedere
  const conferma = window.confirm("Sei sicuro di voler eliminare i dati del logbook?");
  if (!conferma) {
    return; // L'utente ha annullato l'operazione
  }

  // Prepara il body della richiesta, includendo eventuali filtri di data
  const body = {
    identifica: selectedDispositivo, // Il dispositivo selezionato
    startDate: startDate ? startDate.toISOString().split('T')[0] : null, // Converte la data nel formato YYYY-MM-DD
    endDate: endDate ? endDate.toISOString().split('T')[0] : null
  };

  const response = await fetch(`${apiUrl}/delete-logbook-data`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    alert('Dati del logbook eliminati con successo.');
    getLogbookData(selectedDispositivo); // Aggiorna i dati del logbook dopo l'eliminazione
  } else {
    alert('Errore durante l\'eliminazione dei dati del logbook.');
  }
};

  // funzione per invio codice 2fa
  const resend2FA = async () => {
    try {
      const response = await fetch(`${apiUrl}/resend-2fa`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username }) // Invia il nome utente al backend
      });
  
      if (response.ok) {
        alert('Un nuovo codice di autenticazione a due fattori è stato inviato alla tua email.');
      } else {
        const errorMessage = await response.text();
        alert('Errore durante il reinvio del codice 2FA: ' + errorMessage);
      }
    } catch (error) {
      console.error('Errore durante il reinvio del codice 2FA:', error);
      alert('Errore durante il reinvio del codice 2FA.');
    }
  };
  

/// Funzione per condividere il dispositivo
const shareDevice = async () => {
  if (!utenteCondiviso) {
    alert('Per favore inserisci il nome dell\'utente con cui condividere.');
    return;
  }

  try {
    // Invia la richiesta per verificare l'utente e aggiornare la condivisione in un'unica chiamata
/*
    console.log('Dati per condividere il dispositivo:', {
      codice_utente: codiceUtente,
      identifica: selectedDispositivo,
      utente_condiviso: utenteCondiviso // Inserisce direttamente l'username
    });*/

    const response = await fetch(`${apiUrl}/share-device`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        codice_utente: codiceUtente, // Codice utente del proprietario
        identifica: selectedDispositivo,  // Il dispositivo selezionato
        utente_condiviso: utenteCondiviso // Username dell'utente con cui condividere
      }),
    });

    //console.log('Risposta del server:', response);
    if (response.ok) {
      alert('Dispositivo condiviso con successo.');
      setUtenteCondiviso(''); // Resetta il campo di input
      getSharedUsers(selectedDispositivo);
    } else {
      const errorText = await response.text();
      alert('Errore durante la condivisione del dispositivo: ' + errorText);
    }
  } catch (error) {
    console.error('Errore durante la condivisione del dispositivo:', error);
    alert('Errore durante la condivisione del dispositivo: ' + error.message);
  }
};

// Funzione per ottenere la lista degli utenti con cui il dispositivo è condiviso
const getSharedUsers = async (identifica) => {
  try {
    const response = await fetch(`${apiUrl}/get-shared-users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ identifica })
    });

    if (response.ok) {
      const data = await response.json();
      //console.log('Utenti condivisi:', data.sharedUsers); // Aggiungi questo log
      setSharedUsers(data.sharedUsers); // Aggiorna lo stato con la lista degli utenti condivisi
    } else {
      alert('Errore nel recupero degli utenti condivisi');
    }
  } catch (error) {
    console.error('Errore nel recupero degli utenti condivisi:', error);
    alert('Errore nel recupero degli utenti condivisi');
  }
};


// Funzione per rimuovere un singolo utente condiviso
const removeSharedUser = async () => {
  if (!utenteSelezionato) {
    alert('Seleziona un utente da rimuovere.');
    return;
  }
// Chiedi conferma prima di procedere
const conferma = window.confirm("Sei sicuro di voler rimuovere questo utente dalla condivisione?");
if (!conferma) return; // Interrompe l'esecuzione se l'utente annulla
  try {
    const response = await fetch(`${apiUrl}/remove-shared-user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        identifica: selectedDispositivo,
        codice_utente: codiceUtente, // Codice utente del proprietario
        utente_da_rimuovere: utenteSelezionato, // Codice dell'utente da rimuovere
      }),
    });

    if (response.ok) {
      alert('Utente rimosso dalla condivisione con successo.');
      setUtenteSelezionato(''); // Resetta il campo di selezione
      getSharedUsers(selectedDispositivo); // Aggiorna la lista degli utenti condivisi
    } else {
      alert('Errore nella rimozione dell\'utente dalla condivisione.');
    }
  } catch (error) {
    console.error('Errore durante la rimozione dell\'utente dalla condivisione:', error);
    alert('Errore durante la rimozione dell\'utente dalla condivisione.');
  }
};



// Funzione per rimuovere tutte le condivisioni di un dispositivo
const removeAllShares = async (identifica) => {
  const conferma = window.confirm('Sei sicuro di voler rimuovere tutte le condivisioni?');
  if (!conferma) return;

  try {
    const response = await fetch(`${apiUrl}/remove-all-shares`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ identifica }),
    });

    if (response.ok) {
      alert('Tutte le condivisioni sono state rimosse con successo.');
      setSharedUsers([]); // Aggiorna la lista degli utenti condivisi a vuoto
    } else {
      alert('Errore nella rimozione di tutte le condivisioni.');
    }
  } catch (error) {
    console.error('Errore nella rimozione di tutte le condivisioni:', error);
    alert('Errore nella rimozione di tutte le condivisioni.');
  }
};

const removeDevicelog = async (identifica) => {
  const conferma = window.confirm("Sei sicuro di voler disassociare il dispositivo?");
  if (!conferma) return; // Interrompi l'esecuzione se l'utente annulla
  try {
    const response = await fetch(`${apiUrl}/disassociate-device-log`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        identifica,
        codice_utente: codiceUtente, // Codice utente dell'utente loggato
      }),
    });

    if (response.ok) {
      alert('Dispositivo disassociato con successo.');
      // Aggiorna lo stato per rimuovere il dispositivo dalla lista
      setDispositivi(dispositivi.filter(device => device.identifica !== identifica));
    } else {
      const errorText = await response.text();
      alert('Errore durante la disassociazione del dispositivo: ' + errorText);
    }
  } catch (error) {
    console.error('Errore durante la disassociazione del dispositivo:', error);
    alert('Errore durante la disassociazione del dispositivo: ' + error.message);
  }
};


// Funzione per calcolare la differenza di peso tra ieri e oggi alle 19:00
const getWeightDifference = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Funzione di supporto per formattare la data come "YYYY-MM-DD"
  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const todayStr = formatDate(today);
  const yesterdayStr = formatDate(yesterday);

  // Funzione per trovare il dato di peso più vicino alle 19:00 per una determinata data
  const findClosestWeight = (dateStr) => {
    const dayData = datiLogbook.filter(dato => dato.data.startsWith(dateStr));
    if (dayData.length === 0) return null;

    const targetTime = new Date(`${dateStr}T19:00:00`);
    return dayData.reduce((closest, current) => {
      const currentTime = new Date(current.data);
      return Math.abs(currentTime - targetTime) < Math.abs(new Date(closest.data) - targetTime) ? current : closest;
    });
  };

  const yesterdayWeight = findClosestWeight(yesterdayStr)?.peso || null;
  const todayWeight = findClosestWeight(todayStr)?.peso || null;

  return yesterdayWeight !== null && todayWeight !== null ? todayWeight - yesterdayWeight : null;
};

// Aggiungi una funzione per arrotondare a due decimali
const formatValue = (value) => {
  return Math.round(value * 100) / 100;
};

const deregisterUser = async () => {
  const conferma = window.confirm("Sei sicuro di voler deregistrare il tuo account? Tutti i dati saranno eliminati.");

  if (!conferma) return;

  try {
    const response = await fetch(`${apiUrl}/api/deregister-user`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ codiceUtente }) // Inserisce il codice utente loggato
    });

    if (response.ok) {
      alert('Account cancellato con successo.');
      // Esegue il logout e reindirizza alla pagina di login
      logout();
    } else {
      alert('Errore durante la deregistrazione: ' + (await response.text()));
    }
  } catch (error) {
    console.error('Errore durante la deregistrazione:', error);
    alert('Errore durante la deregistrazione.');
  }
};

function SettingsMenu({ onBack, deregisterUser }) {
  return (
    <div style={{
      padding: '20px',
      maxWidth: '320px',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}>
      <h2 style={{
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
        fontSize: '1.5em'
      }}>Impostazioni</h2>

      {/* Container per le checkbox e il pulsante Salva */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {[
          { label: 'Abilita Temperatura', state: enableTemperatura, setter: setEnableTemperatura },
          { label: 'Abilita Rumore', state: enableRumore, setter: setEnableRumore },
          { label: 'Abilita Umidità', state: enableUmidita, setter: setEnableUmidita },
          { label: 'Abilita THI', state: enableThi, setter: setEnableThi },
        ].map(({ label, state, setter }) => (
          <div key={label} style={{
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: '#fff', 
            padding: '10px', 
            borderRadius: '5px', 
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
          }}>
            <input
              type="checkbox"
              checked={state}
              onChange={(e) => setter(e.target.checked)}
              style={{ marginRight: '10px' }}
            />
            <label style={{ color: '#555', fontWeight: '500' }}>{label}</label>
          </div>
        ))}

        {/* Pulsante per salvare le impostazioni */}
        <button
          onClick={updateSettings}
          style={{
            padding: '12px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
            textAlign: 'center',
            transition: 'background-color 0.3s'
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#45a049'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
        >
          Salva impostazioni
        </button>
      </div>

      {/* Pulsante di deregistrazione dell'account */}
      <button
        className="danger"
        style={{
          color: 'white',
          backgroundColor: '#e74c3c',
          borderRadius: '5px',
          padding: '12px 20px',
          marginTop: '20px',
          cursor: 'pointer',
          fontWeight: 'bold',
          display: 'block',
          width: '100%',
          textAlign: 'center',
          transition: 'background-color 0.3s'
        }}
        onClick={deregisterUser}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#c0392b'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#e74c3c'}
      >
        Deregistra Account
      </button>

      {/* Pulsante per tornare alla schermata principale */}
      <button
        onClick={onBack}
        style={{
          marginTop: '10px',
          padding: '12px 20px',
          borderRadius: '5px',
          backgroundColor: '#bdc3c7',
          color: '#333',
          fontWeight: 'bold',
          cursor: 'pointer',
          display: 'block',
          width: '100%',
          textAlign: 'center',
          transition: 'background-color 0.3s'
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#95a5a6'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#bdc3c7'}
      >
        Torna alla schermata principale
      </button>
    </div>
  );
}


// Funzione per caricare le impostazioni iniziali dal database
const loadSettings = async () => {
  try {
    const response = await fetch(`${apiUrl}/get-user-settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ codice_utente: codiceUtente })
    });

    if (response.ok) {
      const settings = await response.json();
      setEnableTemperatura(settings.enable_temperatura);
      setEnableRumore(settings.enable_rumore);
      setEnableUmidita(settings.enable_umidita);
      setEnableThi(settings.enable_thi);
    }
  } catch (error) {
    console.error('Errore nel caricamento delle impostazioni:', error);
  }
};

// Effettua il caricamento delle impostazioni quando il componente è montato
useEffect(() => {
  if (codiceUtente) {
    loadSettings();
  }
}, [codiceUtente]);

// Funzione per aggiornare le impostazioni nel database
const updateSettings = async () => {
  try {
    const response = await fetch(`${apiUrl}/update-user-settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        codice_utente: codiceUtente,
        enable_temperatura: enableTemperatura,
        enable_rumore: enableRumore,
        enable_umidita: enableUmidita,
        enable_thi: enableThi
      })
    });

    if (response.ok) {
      alert('Impostazioni aggiornate con successo!');
    } else {
      alert('Errore durante l\'aggiornamento delle impostazioni');
    }
  } catch (error) {
    console.error('Errore durante l\'aggiornamento delle impostazioni:', error);
  }
};

const zoomChart = (chartRef, scale) => {
  if (chartRef.current) {
    chartRef.current.zoom(scale);
  }
};

const resetZoom = (chartRef) => {
  if (chartRef.current) {
    chartRef.current.resetZoom();
  }
};

let panInterval = null; // Variabile per memorizzare il setInterval

const startPan = (chartRef, direction) => {
  if (chartRef.current) {
    panInterval = setInterval(() => {
      chartRef.current.pan({ x: direction * 50 }); // Scorre continuamente
    }, 100); // Velocità dello scorrimento (100ms)
  }
};

const stopPan = () => {
  if (panInterval) {
    clearInterval(panInterval); // Ferma lo scorrimento
    panInterval = null;
  }
};



return (
  <div className="App">
    {!loggedIn ? (
      !requirePasswordChange ? (
        require2FA ? (
          // Blocco per il codice 2FA
          <div>
            <h2>Inserisci il codice di autenticazione a due fattori</h2>
    <input
      type="text"
      placeholder="Codice 2FA"
      value={twoFactorCode}
      onChange={(e) => setTwoFactorCode(e.target.value)}
    />

{/* Container per i pulsanti */}
<div className="button-column">
      <button
        onClick={() => {
          verify2FA();
          setTwoFactorCode(""); // Svuota la textbox dopo la verifica
        }}
      >
        Verifica Codice
      </button>
      <button
        onClick={() => {
          resend2FA();
          setTwoFactorCode(""); // Svuota la textbox dopo il reinvio
        }}
      >
        Reinvia codice
      </button>
      <button
        onClick={() => {
          setRequire2FA(false);
          setTwoFactorCode(""); // Svuota la textbox quando si torna al login
        }}
      >
        Torna al login
      </button>
    </div>
  </div>

        ) : (
          isSignUp ? (
          <div>
            <h1>Registrazione</h1>
            <input type="text" placeholder="Nome utente" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button className="primary" onClick={signUp}>Registrati</button>
            <p>Hai già un account? <button className="primary" onClick={() => setIsSignUp(false)}>Accedi</button></p>
          </div>
        ) : isRecover ? (
          <div>
            <h1>Recupera credenziali</h1>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button className="primary" onClick={recoverCredentials}>Recupera</button>
            <p>Ricorda le credenziali? <button className="primary" onClick={() => setIsRecover(false)}>Accedi</button></p>
          </div>
        ) : (
          <div>
            <h1>Login</h1>
            <input type="text" placeholder="Nome utente" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className="primary" onClick={login}>Login</button>
            <p>Non ricordi le credenziali? <button className="primary" onClick={() => setIsRecover(true)}>Recupera</button></p>
            <p>Non hai un account? <button className="primary" onClick={() => setIsSignUp(true)}>Registrati</button></p>
          </div>
        )
      )
      ) : (
        <div>
          <h1>Modifica password</h1>
          <input 
            type="password" 
            placeholder="Nuova password" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
          />
          <input 
            type="password" 
            placeholder="Conferma nuova password" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
          />
          <p style={{ color: 'gray' }}>
            La password deve essere di almeno 7 caratteri, contenere una lettera maiuscola e almeno un numero.
          </p>
          <button className="primary" onClick={changePassword}>Cambia password</button>
        </div>
      )
      
    ) : showSettings ? (
      // Mostra il menu delle impostazioni
      <SettingsMenu
        onBack={() => setShowSettings(false)} // Funzione per tornare indietro
        deregisterUser={deregisterUser}       // Funzione di deregistrazione
      />

    ) : selectedDispositivo ? (
      <div>
      
        <h2>Dati del dispositivo {selectedDispositivo}</h2>
         {/* Duplica il pulsante "Torna al menu precedente" qui */}
         <button className="primary"  onClick={tornaAlMenuPrecedente} style={{ marginBottom: '20px' }}>Torna al menu precedente</button>

         {!isOwner && (
  <button className="danger" onClick={() => removeDevicelog(selectedDispositivo)} style={{ marginLeft: '10px' }}>

    Disassocia Dispositivo
  </button>
)}
                 {/* Aggiungi qui la lista degli utenti con cui il dispositivo è condiviso */}
               {/* Aggiungi qui la lista degli utenti con cui il dispositivo è condiviso in un menu a tendina */}
                       {/* Aggiungi qui la lista degli utenti con cui il dispositivo è condiviso in un menu a tendina */}

                       {isOwner && sharedUsers && sharedUsers.length > 0 && (
  <div>
    <h3>Utenti con cui il dispositivo è condiviso:</h3>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <select onChange={(e) => setUtenteSelezionato(e.target.value)}>
        <option value="">Seleziona un utente</option>
        {sharedUsers.map((user, index) => (
          <option key={index} value={user.username}>{user.username}</option>
        ))}
      </select>

      {/* Pulsante per rimuovere la condivisione con un singolo utente */}
      <button
        style={{ marginLeft: '10px' }}
        onClick={() => removeSharedUser(utenteSelezionato)}
        disabled={!utenteSelezionato}
      >
        Rimuovi condivisione
      </button>

      {/* Pulsante per rimuovere tutte le condivisioni */}
      <button
        style={{ marginLeft: '10px' }}
        onClick={() => removeAllShares()}
      >
        Rimuovi tutte le condivisioni
      </button>
    </div>
  </div>
)}

        
         <div>
         
</div>

<div className="weight-difference">
  <h4>Variazione di Peso (ieri - oggi alle 19:00):</h4>
  {weightDifference !== null ? (
    <div className={`difference-box ${weightDifference > 0 ? 'positive' : 'negative'}`}>
      {weightDifference > 0 ? `+${weightDifference.toFixed(2)} kg` : `${weightDifference.toFixed(2)} kg`}
    </div>
  ) : (
    <p>Dati non disponibili</p>
  )}
</div>
        {/* Finestra dei filtri */}
        <div className="finestra-filtri">
  <h4>Filtra per data</h4>
  
  {/* Riga per la data di inizio */}
  <div className="finestra-filtri-row">
  <label style={{ marginRight: '10px' }}>Data inizio:</label>
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      selectsStart
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd/MM/yyyy"
      className="date-picker-input"  // Applica la classe
    />

    <button className="primary" onClick={filterDataByDate}>Filtra Dati</button> {/* Pulsante a destra */}
  </div>

  {/* Riga per la data di fine */}
  <div className="finestra-filtri-row">
    <label  style={{ marginRight: '10px' }} >Data fine:</label>
    <DatePicker
      selected={endDate}
      onChange={(date) => setEndDate(date)}
      selectsEnd
      startDate={startDate}
      endDate={endDate}
      minDate={startDate}
      dateFormat="dd/MM/yyyy"
      className="date-picker-input"  // Applica la classe
    />
    {/* Pulsante a destra */}
    {isOwner ? (
    <button className="danger" onClick={deleteLogbookData}>Elimina Dati</button>
  ) : (
    <span style={{ width: '100px', display: 'inline-block' }}></span> // Placeholder per mantenere l'allineamento
  )}
    
  </div>
</div>
                {/* Container per i grafici */}
                <div className="grafici-container">
          

          <div className="grafico-ciambella">
            <Doughnut data={pesoAttualeData} options={doughnutPesoOptions} />
          </div>
          {enableUmidita && (
          <div className="grafico-ciambella">
            <Doughnut data={umiditaData} options={doughnutUmiditaOptions} />
          </div>
)}
{enableRumore && (
          <div className="grafico-ciambella">
            <Doughnut data={rumoreData} options={doughnutRumoreOptions} />
          </div>
)}
{enableThi && (
          <div className="grafico-ciambella">
          <Doughnut data={thiData} options={doughnutThiOptions} />
          </div>
          )}

        </div>

        <div className="grafico-peso">
  <h3 style={{ marginBottom: '10px' }}>Grafico Lineare Peso</h3>

  {/* Checkbox per bloccare la scala Y */}
  <div style={{ marginBottom: '10px' }}>
    <label>
      <input
        type="checkbox"
        checked={lockYAxisPeso}
        onChange={() => setLockYAxisPeso(!lockYAxisPeso)}
      />
      200kg
    </label>
  </div>

  {/* Pulsanti di zoom e pan */}
  <div className="zoom-buttons">
    <button
      className="primary zoom-arrow"
      onMouseDown={() => startPan(pesoChartRef, -1)}
      onMouseUp={stopPan}
      onMouseLeave={stopPan}
      onTouchStart={() => startPan(pesoChartRef, -1)}
      onTouchEnd={stopPan}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>

    <button className="primary zoom-control" onClick={() => zoomChart(pesoChartRef, 1.2)}>
      <FontAwesomeIcon icon={faSearchPlus} />
    </button>

    <button className="primary zoom-control" onClick={() => zoomChart(pesoChartRef, 0.8)}>
      <FontAwesomeIcon icon={faSearchMinus} />
    </button>

    <button className="primary zoom-control" onClick={() => resetZoom(pesoChartRef)}>
      <FontAwesomeIcon icon={faTimes} />
    </button>

    <button
      className="primary zoom-arrow"
      onMouseDown={() => startPan(pesoChartRef, 1)}
      onMouseUp={stopPan}
      onMouseLeave={stopPan}
      onTouchStart={() => startPan(pesoChartRef, 1)}
      onTouchEnd={stopPan}
    >
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  </div>

  <Line ref={pesoChartRef} data={pesoData} options={pesoOptions} />
</div>


          
        {/* Container per il grafico della temperatura */}
        {enableTemperatura && (
  <div className="grafico-temperatura">
    <h3 style={{ marginBottom: '10px' }}>Grafico Lineare Temperature</h3>

    <div className="zoom-buttons">
      <button
        className="primary zoom-arrow"
        onMouseDown={() => startPan(temperatureChartRef, -1)}
        onMouseUp={stopPan}
        onMouseLeave={stopPan}
        onTouchStart={() => startPan(temperatureChartRef, -1)}
        onTouchEnd={stopPan}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>

      <button className="primary zoom-control" onClick={() => zoomChart(temperatureChartRef, 1.2)}>
        <FontAwesomeIcon icon={faSearchPlus} />
      </button>

      <button className="primary zoom-control" onClick={() => zoomChart(temperatureChartRef, 0.8)}>
        <FontAwesomeIcon icon={faSearchMinus} />
      </button>

      <button className="primary zoom-control" onClick={() => resetZoom(temperatureChartRef)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <button
        className="primary zoom-arrow"
        onMouseDown={() => startPan(temperatureChartRef, 1)}
        onMouseUp={stopPan}
        onMouseLeave={stopPan}
        onTouchStart={() => startPan(temperatureChartRef, 1)}
        onTouchEnd={stopPan}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>

    <Line ref={temperatureChartRef} data={temperatureData} options={temperatureOptions} />
  </div>
)}


{enableThi && (
  <div className="grafico-thi">
    <h3 style={{ marginBottom: '10px' }}>Grafico Lineare THI</h3>

    <div className="zoom-buttons">
      <button
        className="primary zoom-arrow"
        onMouseDown={() => startPan(thiChartRef, -1)}
        onMouseUp={stopPan}
        onMouseLeave={stopPan}
        onTouchStart={() => startPan(thiChartRef, -1)}
        onTouchEnd={stopPan}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>

      <button className="primary zoom-control" onClick={() => zoomChart(thiChartRef, 1.2)}>
        <FontAwesomeIcon icon={faSearchPlus} />
      </button>

      <button className="primary zoom-control" onClick={() => zoomChart(thiChartRef, 0.8)}>
        <FontAwesomeIcon icon={faSearchMinus} />
      </button>

      <button className="primary zoom-control" onClick={() => resetZoom(thiChartRef)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <button
        className="primary zoom-arrow"
        onMouseDown={() => startPan(thiChartRef, 1)}
        onMouseUp={stopPan}
        onMouseLeave={stopPan}
        onTouchStart={() => startPan(thiChartRef, 1)}
        onTouchEnd={stopPan}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>

    <Line ref={thiChartRef} data={thiLineData} options={thiLineOptions} />
  </div>
)}

        
       

        {/* Container per la mappa e l'altitudine */}
        {Math.abs(position[0]) > 1 || Math.abs(position[1]) > 1 ? (
  <div className="mappa-container">
    <div className="mappa">
      <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={dropIcon}>
          <Popup>Posizione corrente: {position[0]}, {position[1]}</Popup>
        </Marker>
        <MapUpdater position={position} />
      </MapContainer>
    </div>
    <div className="altitudine-box">
      m s.l.m.<br />
      {Math.floor(altitudine)} m
    </div>
  </div>
) : (
  <p>Mappa non disponibile</p>
)}
        {isOwner && !selectedDispositivo.condivisione_dispositivo_utente && (
  <div>
    <h3>Aggiorna Nome Dispositivo</h3>
    <input
      type="text"
      placeholder="Nuovo nome"
      value={newDeviceName}
      onChange={(e) => setNewDeviceName(e.target.value)}
    />
    <button onClick={() => updateDeviceName(selectedDispositivo)}>Aggiorna Nome</button>

    <h3>Condividi Dispositivo</h3>
    <input
      type="text"
      placeholder="Nome utente con cui condividere"
      value={utenteCondiviso}
      onChange={(e) => setUtenteCondiviso(e.target.value)}
    />
    <button  style={{ marginBottom: '10px' }} onClick={shareDevice}>Condividi</button>
  </div>
)}


    
        <button className="primary" style={{ marginRight: '10px',marginTop: '10px' }} onClick={tornaAlMenuPrecedente}>Torna al menu precedente</button>
        <button className="primary"  onClick={logout}>Logout</button>
      </div>
    ) : (
      <div>
        <h1>Benvenuto, {loggedInUsername}!</h1>
        <p>Il tuo codice utente è: {codiceUtente}</p>
          {/* Icona per il menu delle impostazioni */}
  <FontAwesomeIcon
    icon={faCog}
    onClick={() => setShowSettings(true)} // Apri il menu delle impostazioni
    style={{
      fontSize: '24px',
      cursor: 'pointer',
      position: 'absolute',
      top: '20px',
      right: '20px',
      color: '#333',
    }}
  />
        <button className="primary"  onClick={getDispositiviAssociati}>Dispositivi Associati</button>

        {showDispositivi && dispositivi.length > 0 &&  (
          <div>
            <h2>Dispositivi associati:</h2>
            {dispositivi.map((dispositivo, index) => (
  <button 
    className="hover" 
    key={index} 
    style={{ marginRight: '10px' }} 
   // onClick={() => getLogbookData(dispositivo.identifica)}
    onClick={() => handleDeviceClick(dispositivo.identifica, dispositivo.isOwner)} // Qui chiamiamo handleDeviceClick
  >
    {/* Visualizza il nome del dispositivo o l'identificativo se il nome non è presente */}
    {dispositivo.condivisione_dispositivo_utente ? `#${dispositivo.nome || dispositivo.identifica}` : dispositivo.nome || dispositivo.identifica}
  </button>
))}
          </div>
        )}

        <div>
          <h2>Registra o Disassocia Dispositivo</h2>
          <input
            type="text"
            placeholder="Inserisci codice"
            value={codiceInput}
            onChange={(e) => setCodiceInput(e.target.value)}
          />
          <button className="primary" onClick={registerDevice} style={{ marginRight: '15px' }} >Registra Dispositivo </button>
          <button className="danger" onClick={() => disassociateDevice(codiceInput)} style={{ marginRight: '15px' }} >Disassocia Dispositivo</button>
          <button className="primary" onClick={() => setShowQRScanner(!showQRScanner)}>
            {showQRScanner ? 'Nascondi QR Scanner' : 'Scansiona QR Code'}
          </button>
          {showQRScanner && <QRScanner onScan={handleQRScan} />}
        </div>

        {registrationSuccess === true && <p>Dispositivo registrato con successo!</p>}
        {registrationSuccess === false && <p>Errore nella registrazione del dispositivo.</p>}

        <button className="primary" onClick={logout} style={{marginTop: '10px'}}>Logout</button>

           
      </div>
      
    )}
  </div>
);

  
  
}

export default App;

